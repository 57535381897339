export function useSupabaseQuery () {
  const route = useRoute()

  const query = new URLSearchParams(route.hash.substring(1))

  const accessToken = query.get('access_token')
  const refreshToken = query.get('refresh_token')
  const errorCode = Number(query.get('error_code'))

  return { accessToken, refreshToken, errorCode }
}
